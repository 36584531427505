import {Routes} from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'employee',
    pathMatch: 'full',
  },
  {
    path: 'category',
    loadComponent: () =>
      import('./components/index').then((com) => com.CategoryGridComponent),
    // canActivate: [AuthGuard]
  },
  {
    path: 'employee',
    loadComponent: () =>
      import('./components/index').then((com) => com.EmployeeGridComponent),
    // canActivate: [AuthGuard]
  },
  {
    path: 'department',
    loadComponent: () =>
      import('./components/index').then((com) => com.DepartmentGridComponent),
    // canActivate: [AuthGuard]
  },
  {
    path: 'leave',
    loadComponent: () =>
      import('./components/index').then((com) => com.CreateLeaveComponent),
    // canActivate: [AuthGuard]
  },
  {
    path: 'gatepass',
    loadComponent: () =>
      import('./components/index').then((com) => com.GatepassCreateComponent),
    // canActivate: [AuthGuard]
  },
  {
    path: 'viewLeave',
    loadComponent: () =>
      import('./components/index').then((com) => com.ManagerGridComponent),
    // canActivate: [AuthGuard]
  },
  {
    path: 'viewGatepass',
    loadComponent: () =>
      import('./components/index').then((com) => com.GatepassGridComponent),
    // canActivate: [AuthGuard]
  },

];


