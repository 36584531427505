import {Component, computed, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {initFlowbite} from 'flowbite';
import {Subscription} from "rxjs";
import {AuthService, LoadingService, NavigateComponent, NotificationService} from "./components";
import {DotLoadingServices} from "./core";
import {NotificationAlertComponent} from "./core/components/notification/notification-alert.component";
import {DotAnimationComponent} from "./core/loading/dot-animation/dot-animation.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, DotAnimationComponent, NotificationAlertComponent, NavigateComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit ,OnDestroy{

  currentNotification = computed(() => this.notificationService.notification());
  private subscriptions: Subscription = new Subscription();
  title = 'Leave Management System';
  isLoading = computed(() => this.dotLoadingServices.isLoading());

  hideOnPages: boolean = false;
  username: string = '';
  password: string = '';
  showError: boolean = false;

  private querySub: Subscription | undefined;

  isLoginPage: boolean = false;

  constructor(
      private loadingService: LoadingService,
      private router: Router,
      public dotLoadingServices: DotLoadingServices,
      private notificationService: NotificationService,
      protected authService: AuthService,
      private activatedRoute: ActivatedRoute,
  ) {}


  ngOnInit(): void {
    this.querySub = this.activatedRoute.queryParams.subscribe(params => {
      const keyValue = params['auth-key'];
      if (keyValue) {
        this.authService.signIn(keyValue).subscribe(
          response => {
            this.showError = false;
          },
          error => {
            console.error('Login failed', error);
            this.triggerError();
          }
        );
      }
    });

    initFlowbite();
    this.subscriptions.add(
      this.loadingService.isLoading.subscribe((isLoading) => {
        this.dotLoadingServices.setLoading(isLoading);
      })
    );

    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.hideOnPages = this.router.url.includes('/login' && '/error' && '/authenticate');
        }
      })
    );
  }

  triggerError() {
    this.showError = false;
    setTimeout(() => this.showError = true, 0);
  }



  ngOnDestroy(): void {
    if (this.querySub)
      this.querySub.unsubscribe();

    this.subscriptions.unsubscribe();
  }
  clearNotification() {
    const notification = this.currentNotification();
    if (notification) {
      this.notificationService.clearNotification(notification.id);
    }
  }
}
